/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Button, Modal, ModalBody, ModalFooter, Col, CustomInput, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input  } from 'reactstrap';

/**
 * Internal Dependencies
 */
import {
    updateAuth as actionUpdateAuth,
    updateSettings as actionUpdateSettings,
    sortAuth as actionSortAuth
} from '../../actions';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';
import FancyBox from '../fancybox';
import PagePopupSearch from '../page-popup-search';
import Messenger from '../messenger';
import { initNavbar } from '../../../../common-assets/js/rootui-parts/initNavbar';
import DatePicker from '../../components/date-time-picker';
import Select from 'react-select';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

/**
 * Component
 */
//   const api_url = "https://crm.esenseit.in/server/api.php";
  // const api_url = "http://192.168.43.110/esense_crm/server/api.php";
  // var api_url ="http://173.249.5.10/esense_crm/server/api.php"

 const api_url = "http://192.168.1.100/esense_crm/server/api.php";
 // const api_url = "http://173.212.194.236/esense_crm/server/api.php";
 // var api_url ="http://173.249.5.10/esense_crm/server/api.php"
class PageNavbar extends Component {
    constructor( props ) {
        super( props );

        window.RootUI.initNavbar();

        this.state = {
            mobileMenuShow: false,
            modalOpen: false,
            login_user_name:'',
            night_mode2:Cookies.get('night_mode_cookie'),
            // firstVal: 'Select Date',
            // secondVal: '',
            // thirdVal: '',
            // add_task: '',
            // date: '',
            // time: '',
            // category: '',
            // contact_for: '',
            // responsible_for: '',
            // category_array: [],
            // task_array: [],
            // // category_array_new: [],
            // new_array_category: [],
            // responsible_array: [],
            // task_status_array: [],
            // new_array_responsible: [],
            // responsible_for_array_new: '',
            // task_status_new: '',
            // category_array_new: '',
        };

        this.logOut = this.logOut.bind( this );
        this.renderSubmenus = this.renderSubmenus.bind( this );
        this.renderRightMenuItems = this.renderRightMenuItems.bind( this );
        // this.toggle = this.toggle.bind( this );
        // this.fetch_category();
        // this.fetch_responsible();
        // this.fetch_task_status();
        // this.fetch_task();
        this.fetch_admin();
        // this.SubmitForm = this.SubmitForm.bind(this);
    }
    // toggle() {
    //     this.setState( ( prevState ) => ( {
    //         modalOpen: ! prevState.modalOpen,
    //     } ) );
    // }


    fetch_admin() {
        const {
          auth,
      } = this.props;
      const {
          updateAuth,
          sortAuth,
      } = this.props;
          var params = {
              mode: "fetch_singl_user",
             id:auth.crm_token
              }
              console.log("fetch admin params", params);
              console.log("api",api_url);
              const res = fetch(api_url, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
          }).then((response) => response.json())
              .then(json => {

                    var message = json;
                      console.log("fetch_messfetch adminage****************************************8",message)
                    if(message.status == true){
                      sortAuth( {
                           user_name:{value:message.data[0].id, label:message.data[0].user_username} ,
                           archive_user_name:{value:message.data[0].id, label:message.data[0].user_username} ,
                       } );
                        this.setState({login_user_name:message.data[0].user_fname + " " + message.data[0].user_lname  })
                          console.log("fetch_messfetch adminage****************************************8",this.state.login_user_name)
                    }
                    else{
                        this.setState({login_user_name:[]})
                          console.log("fetch_messfetch adminage blanck****************************************8",this.state.login_user_name)
                    }

      })


    }

    // SubmitForm() {
    //     // const {
    //     //     addToast,
    //     // } = this.props;
    //     // this.setState({
    //     //     loading: true,
    //     // })
    //     // const {
    //     //     name_n,
    //     //     email_id,
    //     //     mobile_no,
    //     //     city,
    //     //     speciality,
    //     //     hospital_number,
    //     //     hospital_name,
    //     //     address,
    //     //     latitude,
    //     //     longitude,
    //     //
    //     // } = this.state;
    //
    //     // var speciality_id = [];
    //     // for (var i = 0; i < speciality.length; i++) {
    //     //     speciality_id.push(speciality[i].value);
    //     // }
    //     // console.log("this.state.fname", this.state.fname);
    //     console.log("dateeeeeeeeeeeeee",format(this.state.date, 'yyyy-MM-dd'))
    //     console.log("timeeeeeeeeeeeeee",format(this.state.time, 'HH:mm:ss'))
    //
    //     const params = {
    //       mode: 'add_new_task',
    //       add_task: this.state.add_task,
    //       date: format(this.state.date, 'yyyy-MM-dd'),
    //       time: format(this.state.time, 'HH:mm:ss'),
    //       category: this.state.category_array_new.label,
    //       contact_for: this.state.contact_for,
    //       responsible_for: this.state.responsible_for_array_new.label,
    //       status: this.state.task_status_new.label,
    //       // skype: this.state.skype,
    //       // website: this.state.website,
    //       // linkedin: this.state.linkedin,
    //       // twitter: this.state.twitter,
    //       // address: this.state.address,
    //       // binfo: this.state.binfo,
    //       // date:""
    //
    //     }
    //
    //     console.log("params0", params);
    //
    //         console.log("okay");
    //         // console.log("Addjobs", params);
    //
    //         var submit_doctor = null;
    //         const res = fetch("api_uri", {
    //             method: 'POST',
    //             body: JSON.stringify(params),
    //             headers: {
    //                 "Content-type": "application/json; charset=UTF-8",
    //             }
    //         }).then((response) => response.json())
    //             .then(json => {
    //                 console.log("response_fetchedddddddddddddddddddddd",json)
    //                 submit_doctor = json;
    //             })
    //
    //
    //             // .then(() => {
    //                 // this.setState({ submit_doctor_array: submit_doctor["data"] });
    //                 // console.log("submit_doctor", submit_doctor);
    //                 // console.log(" submit_doctor.status", submit_doctor.status);
    //                 //
    //                 // if (submit_doctor.status == true) {
    //                 //     setTimeout(() => {
    //                 //         this.setState({
    //                 //             loading: false,
    //                 //         }),
    //                 //             addToast({
    //                 //                 title: 'Doctor Added',
    //                 //                 content: submit_doctor.message,
    //                 //                 time: new Date(),
    //                 //                 duration: 8000,
    //                 //             });
    //                 //     }, 600);
    //                 //     this.setState({
    //                 //         name_n: '',
    //                 //         email_id: '',
    //                 //         mobile_no: '',
    //                 //         city: '',
    //                 //         speciality: '',
    //                 //         hospital_number: '',
    //                 //         hospital_name: '',
    //                 //         address: '',
    //                 //
    //                 //     })
    //                 //     this.fetch_Doctor();
    //                 // }
    //
    //             // });
    //
    // }
    //
    // fetch_task() {
    //
    //     // console.log("fetch city", params);
    //     const params = {
    //       mode: 'fetch_all_task'
    //     }
    //     var fetch_task = null;
    //     const res = fetch("http://192.168.1.100/esense_crm/server/api.php", {
    //         method: 'POST',
    //         body: JSON.stringify(params),
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8",
    //         }
    //     }).then((response) => response.json())
    //         .then(json => {
    //             console.log(json)
    //             fetch_task = json;
    //         })
    //         .then(() => {
    //             this.setState({ task_array: fetch_task["data"] });
    //             console.log("task_array", this.state.task_array);
    //             console.log("  fetch_task", fetch_task["data"]);
    //
    //
    //         })
    //
    // }

    // fetch_category() {
    //
    //     // console.log("fetch city", params);
    //     const params = {
    //       mode: 'fetch_all_category'
    //     }
    //     var category_data = null;
    //     const res = fetch("http://192.168.1.100/esense_crm/server/api.php", {
    //         method: 'POST',
    //         body: JSON.stringify(params),
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8",
    //         }
    //     }).then((response) => response.json())
    //         .then(json => {
    //             console.log(json)
    //             category_data = json;
    //         })
    //         .then(() => {
    //             this.setState({ category_array: category_data["data"] });
    //             console.log("category_array", this.state.category_array);
    //             console.log(" job_data.status", category_data.status);
    //
    //            // this.setState({
    //            //  city_arraypp:city_data["data"]
    //            // })
    //         })
    //
    // }

    // fetch_responsible() {
    //
    //     // console.log("fetch city", params);
    //     const params = {
    //       mode: 'fetch_username_only'
    //     }
    //     var responsible_data = null;
    //     const res = fetch("http://192.168.1.100/esense_crm/server/api.php", {
    //         method: 'POST',
    //         body: JSON.stringify(params),
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8",
    //         }
    //     }).then((response) => response.json())
    //         .then(json => {
    //             console.log("fetch_username_only",json)
    //             responsible_data = json;
    //         })
    //         .then(() => {
    //             this.setState({ responsible_array: responsible_data["data"] });
    //             console.log("responsible_array", this.state.responsible_array);
    //             console.log(" job_data.status", responsible_data.status);
    //
    //            // this.setState({
    //            //  city_arraypp:city_data["data"]
    //            // })
    //         })
    //
    // }

    // fetch_task_status() {
    //
    //     // console.log("fetch city", params);
    //     const params = {
    //       mode: 'fetch_all_status'
    //     }
    //     var task_status_data = null;
    //     const res = fetch("http://192.168.1.100/esense_crm/server/api.php", {
    //         method: 'POST',
    //         body: JSON.stringify(params),
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8",
    //         }
    //     }).then((response) => response.json())
    //         .then(json => {
    //             console.log("fetch_username_only",json)
    //             task_status_data = json;
    //         })
    //         .then(() => {
    //             this.setState({ task_status_array: task_status_data["data"] });
    //             console.log("task_status_array", this.state.task_status_array);
    //             console.log(" job_data.status", task_status_data.status);
    //
    //            // this.setState({
    //            //  city_arraypp:city_data["data"]
    //            // })
    //         })
    //
    // }

    componentDidMount() {
        $( document ).on( 'keydown.rui-navbar', ( e ) => {
            const {
                mobileMenuShow,
            } = this.state;

            if ( mobileMenuShow && e.keyCode === 27 ) {
                this.setState( {
                    mobileMenuShow: ! mobileMenuShow,
                } );
            }
        } );
    }

    componentWillUnmount() {
        $( document ).off( 'keydown.rui-navbar' );
    }

    logOut() {
        const {
            updateAuth,
        } = this.props;

        updateAuth( {
            token: '',
        } );
    }

    renderSubmenus( nav, isMobile, level = 1 ) {
        return Object.keys( nav ).map( ( url ) => {
            const data = nav[ url ];

            const isActive = window.location.hash === `#${ url }`;

            const LinkContent = data.name ? (
                <>
                    { data.icon ? (
                        <>
                            <Icon name={ data.icon } />
                            <span>{ data.name }</span>
                            <span className={ data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle' } />
                        </>
                    ) : (
                        data.name
                    ) }
                </>
            ) : '';

            return (
                <React.Fragment key={ `${ url }-${ data.name }` }>
                    { data.sub ? (
                        <Dropdown tag="li" className={ classnames( isActive ? 'active' : '' ) } direction={ level === 1 ? 'up' : 'right' } openOnHover={ ! isMobile } showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                                { LinkContent }
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                { this.renderSubmenus( data.sub, isMobile, level + 1 ) }
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <li className={ classnames( 'nav-item', isActive ? 'active' : '' ) }>
                            <Link
                                to={ data.sub ? '#' : url }
                                className="nav-link"
                            >
                                { LinkContent }
                            </Link>
                        </li>
                    ) }
                </React.Fragment>
            );
        } );
    }

    renderRightMenuItems( isMobile ) {
        const {
            settings,
            updateSettings,
        } = this.props;

        const countriesDropdown = [
            {
                name: 'USA',
                img: settings.img_country.usa,
            },
            {
                name: 'China',
                img: settings.img_country.china,
            },
            {
                name: 'Germany',
                img: settings.img_country.germany,
            },
            {
                name: 'Japan',
                img: settings.img_country.japan,
            },
            {
                name: 'Spain',
                img: settings.img_country.spain,
            },
        ];

        return (
            <>
                <li className="nav-item ">
                    {/* <FancyBox
                        tagName="a"
                        className={
                            classnames(
                                'd-flex',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                        touch={ false }
                        closeExisting
                        parentEl={ null }
                        popupClassName="rui-popup rui-popup-search container"
                        popupRender={ () => (
                            <PagePopupSearch settings={ settings } />
                        ) }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="search" />
                                <span>Search</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="search" />
                            </span>
                        ) }
                    </FancyBox> */}
                </li>
                {/* <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle> */}
                    {/* <Dropdown.Toggle
                        tag="a"
                        href="#"
                        className={
                            classnames(
                                'dropdown-item',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="flag" />
                                <span>Language</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="flag" />
                            </span>
                        ) }
                    </Dropdown.Toggle> */}
                    {/* <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-language" modifiers={ { offset: { offset: '0,12' } } }>
                        { countriesDropdown.map( ( data ) => (
                            <li key={ data.name }>
                                <Link to="#" className="rui-navbar-language">
                                    <span className="rui-navbar-language-img">
                                        <img src={ data.img } alt={ data.name } />
                                    </span>
                                    { data.name }
                                </Link>
                            </li>
                        ) ) }
                    </Dropdown.Menu> */}
                {/* </Dropdown> */}
                <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
                    {/* <Dropdown.Toggle
                        tag="a"
                        href="#"
                        className={
                            classnames(
                                'dropdown-item',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="bell" />
                                <span>Notifications</span>
                                <span className="badge badge-circle badge-brand">3</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="bell" className="mr-0" />
                                <span className="badge badge-circle badge-brand">3</span>
                            </span>
                        ) }
                    </Dropdown.Toggle> */}
                    <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-notice d-none" modifiers={ { offset: { offset: '0,12' } } }>
                        <li className="rui-navbar-dropdown-title mb-10">
                            <div className="d-flex align-items-center">
                                <h2 className="h4 mb-0 mr-auto">Notifications</h2>
                                <Link to="/profile" className="btn btn-custom-round">
                                    <Icon name="link2" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-success media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img"><img src={ settings.users[ 4 ].img } alt="" /></span>
                                    <span className="media-body">
                                        <span className="media-title">Esense It CRM</span>
                                        <small className="media-subtitle">Bring abundantly creature great...</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img">C</span>
                                    <span className="media-body">
                                        <span className="media-title">Change Design</span>
                                        <small className="media-subtitle">Design</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img bg-transparent"><img src={ settings.img_file.zip } className="icon-file" alt="" /></span>
                                    <span className="media-body">
                                        <span className="media-title">Added banner archive</span>
                                        <small className="media-subtitle">Commerce</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                    </Dropdown.Menu>
                </Dropdown>
                <li className="nav-item d-none">
                    <FancyBox
                        tagName="a"
                        className={
                            classnames(
                                'd-flex',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                        keyboard={ false }
                        touch={ false }
                        closeExisting
                        popupClassName="rui-popup rui-popup-messenger"
                        popupRender={ () => (
                            <Messenger settings={ settings } />
                        ) }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="message-circle" />
                                <span>Messenger</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="message-circle" />
                            </span>
                        ) }
                    </FancyBox>
                </li>
                { ! isMobile ? (
                    <Dropdown tag="li" direction="up" openOnHover showTriangle>
                        <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6 d-none">
                            <img src={ settings.users[ 0 ].img } alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu tag="ul" className="nav dropdown-menu d-none">
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="plus-circle" />
                                    <span>Create new Post</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="users" />
                                    <span>Manage Users</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="check-circle" />
                                    <span>Check Updates</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="nav-link"
                                    onClick={ this.logOut }
                                >
                                    <Icon name="log-out" />
                                    <span>Log Out</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : '' }
                <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
                    <Dropdown.Toggle
                        tag="a"
                        href="#"
                        className={
                            classnames(
                                'dropdown-item mnr-5',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                        nav-link
                    >
                        { isMobile ? (
                            <>
                                <Icon name="more-vertical" />
                                <span>More</span>
                                <span className="rui-dropdown-circle" />
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="more-vertical" />
                            </span>
                        ) }
                    </Dropdown.Toggle>
                    <Dropdown.Menu tag="ul" className="nav dropdown-menu" modifiers={ { offset: { offset: '0,12' } } }>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleNightMode"
                                    checked={ settings.night_mode}
                                    onChange={ () => {
                                        updateSettings( {
                                            night_mode: ! settings.night_mode,
                                        } );
                                        console.log("oooooooooooo", ! settings.night_mode  );
                                        Cookies.set( 'night_mode_cookie', ! settings.night_mode);
                                        console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get( 'night_mode_cookie'));

                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                    <Icon name="moon" />
                                    <span>Night Mode</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleSpotlightMode"
                                    checked={ settings.spotlight_mode }
                                    onChange={ () => {
                                        updateSettings( {
                                            spotlight_mode: ! settings.spotlight_mode,
                                        } );
                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleSpotlightMode">
                                    <Icon name="square" />
                                    <span>Spotlight Mode</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleSectionLines"
                                    checked={ settings.show_section_lines }
                                    onChange={ () => {
                                        updateSettings( {
                                            show_section_lines: ! settings.show_section_lines,
                                        } );
                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleSectionLines">
                                    <Icon name="layout" />
                                    <span>Show section lines</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li className="dropdown-menu-label">Sidebar</li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleDarkSidebar"
                                    checked={ settings.sidebar_dark }
                                    onChange={ () => {
                                        updateSettings( {
                                            sidebar_dark: ! settings.sidebar_dark,
                                        } );
                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleDarkSidebar">
                                    <Icon name="sidebar" />
                                    <span>Dark</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleStaticSidebar"
                                    checked={ settings.sidebar_static }
                                    onChange={ () => {
                                        updateSettings( {
                                            sidebar_static: ! settings.sidebar_static,
                                        } );
                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleStaticSidebar">
                                    <Icon name="sidebar" />
                                    <span>Static</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li className="dropdown-menu-label">Navbar</li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleDarkNavbar"
                                    checked={ settings.nav_dark }
                                    onChange={ () => {
                                        updateSettings( {
                                            nav_dark: ! settings.nav_dark,
                                        } );
                                    } }
                                />
                                { /* eslint-disable-next-line */ }
                                <label className="dropdown-item custom-control-label" htmlFor="toggleDarkNavbar">
                                    <Icon name="menu" />
                                    <span>Dark</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    render() {
        const {
            settings,
        } = this.props;

        const {
            mobileMenuShow,
        } = this.state;

        // var obj = this.state.category_array.map(item => {
        //     return {
        //         value: item.id,
        //         label: item.category
        //     }
        // });
        //
        // var responsible_obj = this.state.responsible_array.map(item => {
        //     return {
        //         value: item.id,
        //         label: item.user_fname
        //     }
        // });
        //
        // var task_status_obj = this.state.task_status_array.map(item => {
        //     return {
        //         value: item.id,
        //         label: item.status
        //     }
        // });

        const colorOptions = [
              { value: 'blue', label: 'Blue' },
              { value: 'ocean', label: 'Ocean' },
              { value: 'red', label: 'Red' },
              { value: 'yellow', label: 'Yellow' },
              { value: 'purple', label: 'Purple' },
              { value: 'orange', label: 'Orange' },
              { value: 'green', label: 'Green' },
          ];

          const customStyles = {
              control: ( css, state ) => {
                  return {
                      ...css,
                      borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
                      '&:hover': {
                          borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
                      },
                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(114, 94, 195, 0.2)' : '',
                  };
              },
              option: ( css, state ) => {
                  let bgc = '';

                  if ( state.isSelected ) {
                      bgc = '#725ec3';
                  } else if ( state.isFocused ) {
                      bgc = 'rgba(114, 94, 195, 0.2)';
                  }

                  return {
                      ...css,
                      backgroundColor: bgc,
                  };
              },
              multiValueLabel: ( css ) => {
                  return {
                      ...css,
                      color: '#545b61',
                      backgroundColor: '#eeeeef',
                  };
              },
          };

        return (
            <>
                { /* Nav Menu */ }
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-top',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_sticky ? 'rui-navbar-sticky' : '',
                        settings.nav_fixed ? 'rui-navbar-fixed' : '',
                        settings.nav_expand ? `rui-navbar-expand-${ settings.nav_expand }` : '',
                    )
                }
                >
                    <div className="rui-navbar-brand">
                        { settings.nav_logo_path ? (
                            <Link to={ settings.nav_logo_url } className="rui-navbar-logo">
                                {/* <img src={ settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path } alt="" width={ settings.nav_logo_width } />
                                 */}
                                 <h2 style={{whiteSpace:'nowrap',marginTop:'20px'}} >ESENSE CRM</h2>



                            </Link>
                        ) : '' }

                        <button className="yay-toggle rui-yaybar-toggle" type="button">
                            <span />
                        </button>
                    </div>
                    <div className={ `container${ settings.nav_container_fluid ? '-fluid' : '' }` }>
                        <div className="rui-navbar-content">
                          <Link to="/colors" className="media-link" style={{width:"100%", }}>
                            <Button color="brand" className="btn-long" style={ { marginRight: "10px"} } >
                                <span className="text">Add Contact</span>

                            </Button>
                            </Link>
                    {    /*  <Button color="brand" className="btn-long"  onClick={ this.toggle }>
                              <span className="icon">
                                  <Icon name="plus" />
                              </span>
                              <span className="text">Add Task</span>
                          </Button>*/}
                           <span style={{whiteSpace:'nowrap',textTransform: "capitalize", marginTop: "10px", fontWeight:"bold", fontSize:"14px"}} >{this.state.login_user_name}</span>
                            <ul className="nav">
                                { this.renderSubmenus( settings.navigation ) }
                            </ul>
                            <ul className="nav rui-navbar-right">
                                { this.renderRightMenuItems() }
                            </ul>
                        </div>
                    </div>
                </nav>

                { /* Mobile Menu */ }
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-mobile',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_expand ? `rui-navbar-expand-${ settings.nav_expand }` : '',
                        mobileMenuShow ? 'rui-navbar-show' : '',
                    )
                }
                >
                    <div className="rui-navbar-head">
                        { ! mobileMenuShow ? (
                            <button className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle" type="button" aria-label="Toggle side navigation">
                                <span />
                            </button>
                        ) : '' }
                        { settings.nav_logo_path ? (
                            <Link to={ settings.nav_logo_url } className="rui-navbar-logo mr-auto">
                                {/* <img src={ settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path } alt="" width={ settings.nav_logo_width } /> */}
                                <h2 style={{whiteSpace:'nowrap',marginTop:'15px',marginBottom:'0px',fontSize:"14px",marginLeft:"-12px"}}>ESENSE CRM</h2><br/>
                                {' '}
                                <span style={{whiteSpace:'nowrap',textTransform: "capitalize",marginLeft: "-86px",marginTop: "32px"}} >{this.state.login_user_name}</span>
                                <h4></h4>

                            </Link>
                        ) : '' }

                           <Link to="/colors" className="media-link" style={{marginRight:"7px"}}>
                                <Button color="brand" className="btn-long" style={{width:"100%",paddingRight: "9px",paddingLeft:"9px",}}>
                                    <span className="text">Add Contact</span>
                                </Button>
                            </Link>



                        <Dropdown tag="div" direction="up" showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar d-none">
                                <img src={ settings.users[ 0 ].img } alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="plus-circle" />
                                        <span>Create new Post</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="users" />
                                        <span>Manage Users</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="check-circle" />
                                        <span>Check Updates</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="#"
                                        className="nav-link"
                                        onClick={ this.logOut }
                                    >
                                        <Icon name="log-out" />
                                        <span>Log Out</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button
                            className="navbar-toggler rui-navbar-toggle ml-5"
                            onClick={ () => {
                                this.setState( {
                                    mobileMenuShow: ! mobileMenuShow,
                                } );
                            } }
                        >
                            <span />
                        </button>
                    </div>
                    <Collapse isOpen={ mobileMenuShow } className="navbar-collapse rui-navbar-collapse">
                        <div className="rui-navbar-content">
                            <ul className="nav">
                                { this.renderSubmenus( settings.navigation, true ) }
                                { this.renderRightMenuItems( true ) }
                            </ul>
                        </div>
                    </Collapse>
                </nav>
                <div
                    className="rui-navbar-bg"
                    onClick={ () => {
                        this.setState( {
                            mobileMenuShow: ! mobileMenuShow,
                        } );
                    } }
                    onKeyUp={ () => {} }
                    role="button"
                    tabIndex={ 0 }
                />


        {  /*     <Modal
                      isOpen={ this.state.modalOpen }
                      toggle={ this.toggle }
                      className={ this.props.className }
                      fade
                  >
                      <div className="modal-header">
                          <h5 className="modal-title h2">Add Task</h5>
                          <Button className="close" color="" onClick={ this.toggle }>
                              <Icon name="x" />
                          </Button>
                      </div>
                      <ModalBody>
                      <FormGroup>
                          <Label>Add New Task</Label>
                          <Input type="text" name="email" id="emailInput1" placeholder="New Task" value={this.state.add_task}
                          onChange={(e) => {
                              this.setState({
                                  add_task: e.target.value,
                              });
                          }} />
                      </FormGroup>
                      <FormGroup>
                          <Label for="emailInput2">When Should it be done?</Label>
                          <div className="row">
                          <div className="col-6">
                              <DatePicker
                                selected={this.state.date}
                                onChange={ ( val ) => {
                                    this.setState( {
                                        date: val,
                                    } );
                                } }
                                placeholder="Select date"
                                dateFormat="yyyy/MM/dd"
                                className="rui-datetimepicker form-control w-auto"
                            />
                            </div>
                            <div className="col-6">
                            <DatePicker
                               selected={this.state.time}
                               onChange={ ( val ) => {
                                   this.setState( {
                                       time: val,
                                   } );
                               } }
                               showTimeSelect
                               showTimeSelectOnly
                               placeholder="Select time"
                               dateFormat="h:mm aa"
                               className="rui-datetimepicker form-control w-auto"
                           />
                          </div>
                          </div>
                      </FormGroup>
                      <FormGroup disabled>
                          <Label for="emailInput3">Choose Category</Label>
                          <Select
                          value={this.state.category_array_new}
                          onChange={(e) => {
                              this.setState({
                                  category_array_new: e,
                              });
                          }}

                          defaultValue={obj[1]}
                          options={obj}
                          styles={customStyles}
                           />
                      </FormGroup>
                      <FormGroup disabled>
                          <Label for="emailInput3">Contact for</Label>
                          <Input type="text" name="email" id="emailInput3" placeholder="Number" value={this.state.contact_for}
                          onChange={(e) => {
                              this.setState({
                                  contact_for: e.target.value,
                              });
                          }} />
                      </FormGroup>
                      <FormGroup disabled>
                          <Label for="emailInput3">Responsible for</Label>
                          <Select
                          value={this.state.responsible_for_array_new}
                          onChange={(e) => {
                              this.setState({
                                  responsible_for_array_new: e,
                              });
                          }}

                          defaultValue={responsible_obj[1]}
                          options={responsible_obj}
                          styles={customStyles}
                           />
                      </FormGroup>
                      <FormGroup disabled>
                          <Label for="emailInput3">Status</Label>
                          <Select
                          value={this.state.task_status_new}
                          onChange={(e) => {
                              this.setState({
                                  task_status_new: e,
                              });
                          }}

                          defaultValue={task_status_obj[1]}
                          options={task_status_obj}
                          styles={customStyles}
                           />
                      </FormGroup>
                      </ModalBody>
                      <ModalFooter>
                          <Button color="secondary" onClick={ this.toggle }>Close</Button>
                          { ' ' }
                          <button className="btn btn-brand" type="button" onClick={this.SubmitForm}>Save</button>
                      </ModalFooter>
                  </Modal> */}
            </>
        );
    }
}

export default connect( ( { settings, auth, sort } ) => (
    {
        settings,auth, sort
    }
), {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings,
    sortAuth: actionSortAuth
} )( PageNavbar );
